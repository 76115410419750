<template>
  <div class="account-list">
    <div class="account-list-title">
      <p class="name">{{title}}</p>
      <el-input class="cond" v-model="pagination.name"
                clearable
                @change="loadAccount"
                placeholder="请输入组织编号/组织名称" size="small">
        <i class="el-input__icon el-icon-search" slot="prefix"></i>
      </el-input>
      <el-button size="mini" type="primary"
                 v-if="$btnCheck('system_org_account_register')"
                 @click="loadAccount" style="margin-left: 8px">新增组织
      </el-button>
    </div>

    <el-table
      class="account-list-table"
      :row-key="record => record.uuid"
      :data="accounts"
      :loading="loading"
      ref="multipleTable"
      :reserve-selection="true"
      highlight-current-row
      @sort-change="sortChange"
      :default-sort="{prop: 'createdAt', order: 'descending'}"
      @selection-change="handleSelectionChange"
    >
      <empty slot="empty"></empty>
      <el-table-column
        v-if="select"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="组织信息" width="240px">
        <template slot-scope="scope">
          <p> {{ scope.row.uuid }}</p>
          <p> {{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="组织简称" width="130px" prop="simpleName" align="center"></el-table-column>
      <el-table-column label="组织类型" width="120px" prop="orgType" align="center"
                       :sort-orders="['ascending', 'descending']" sortable="custom">
        <template slot-scope="scope">
          <p>{{orgs[scope.row.orgType]}}</p>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="120px" prop="status" align="center"
                       :sort-orders="['ascending', 'descending']" sortable="custom">
        <template slot-scope="scope">
          <p>{{scope.row.status | filterStatus}}</p>
        </template>
      </el-table-column>

      <el-table-column label="账户余额" prop="money" align="center" :sort-orders="['ascending', 'descending']"
                       sortable="custom">
        <template slot-scope="scope">
          {{(scope.row.money /100) | F1000}}
        </template>
      </el-table-column>
      <el-table-column label="最大人数" align="center" prop="userLimit"></el-table-column>
      <el-table-column label="注册时间" align="center" min-width="130px" prop="createdAt"
                       :sort-orders="['ascending', 'descending']" sortable="custom">
        <template slot-scope="scope">
          {{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
        </template>

      </el-table-column>
      <slot></slot>
    </el-table>

    <el-pagination
      @current-change="handleAccountList"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>

    <el-dialog
      v-if="$btnCheck('system_org_account_register')"
      width="520px"
      :visible.sync="showOrgAdd"
      title="新增组织"
    >
      <el-form label-width="80px" style="padding-right: 16px">
        <el-form-item>
          <span class="yk-label__require" slot="label">企业全称</span>
          <el-input v-model="org.name"/>
        </el-form-item>

        <el-form-item label="企业简称">
          <el-input v-model="org.simpleName"/>
        </el-form-item>

        <el-form-item>
          <span class="yk-label__require" slot="label">手机帐号</span>
          <el-input v-model="org.phone"/>
        </el-form-item>

        <el-form-item>
          <span class="yk-label__require" slot="label">登录密码</span>
          <el-input v-model="user.password"/>
        </el-form-item>
        <el-form-item>
          <span class="yk-label__require" slot="label">组织类型</span>
          <el-select v-model.number="user.orgType">
            <el-option :value="idx" v-for="(org, idx) in orgs" :key="idx" :label="org">
              {{org}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="handleOrgCreate">确认</el-button>
          <el-button size="small" @click="showOrgAdd = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
  import {checkObj} from "../../../../utils/check";
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    mounted() {
      this.loadAccount()
    },
    computed: {
      ...mapGetters(['org', 'prove']),
    },

    filters: {
      filterRunStatus(runStatus) {
        switch (runStatus) {
          case 0:
            return '未知'
          case 1:
            return '正常运行'
          case 2:
            return '维修中'
        }
      },

      filterStatus(status) {
        switch (status) {
          case 0:
            return '初始化'
          case 1:
            return '审核中'
          case 2:
            return '已拒绝'
          case 3:
            return '已通过'
        }
      },

    },
    props: {
      select: Boolean,
      title: {
        type: String,
        default: '组织列表'
      },
      accountList: {
        type: Function,
        default: function (pagination, fn) {
          this.$api('account.org.list', pagination).then(data => fn(data))
          return {}
        }
      },
      accountUnion: {
        type: Function,
        default: function (pagination, fn) {
          this.$api('account.org.union', pagination).then(data => fn(data))
          return {}
        }
      }
    },
    data: function () {
      return {
        accounts: [],
        orgs: ['请选取组织类型', '运营商'],
        loading: false,
        showOrgAdd: false,
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
          orderCreatedAt: 1,
          orderStatus: 0,
          orderOrgType: 0,
          orderMoney: 0,
        },
        org: {
          name: "",
          simpleName: "",
          orgType: 1,
          phone: "",
          password: "",
          createProveUuid: "",
          createOrgUuid: ""
        },
        selects: []
      }
    },
    methods: {
      sortChange(val) {
        this.pagination.orderCreatedAt = 0
        this.pagination.orderSales = 0
        this.pagination.orderLinePrice = 0
        this.pagination.orderStocks = 0
        let name = val.prop.firstUpperCase()
        this.pagination['order' + name] = val.order ? (val.order === 'descending' ? 1 : 2) : 0
        this.loadAccount()

      },
      handleAccountList(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        this.loading = true
        let vue = this
        vue.accountList(this.pagination, data => {
          vue.accounts = data || []
          let selects = vue.selects.slice(0, vue.selects.length)
          this.$nextTick(() => {
            selects.forEach(item => {
              for (let i = 0; i < this.accounts.length; i++) {
                if (vue.accounts[i].uuid === item.uuid) {
                  vue.$refs.multipleTable.toggleRowSelection(item, true);
                  break
                }
              }
            })
          })
          this.loading = false
        })
      },
      loadAccount() {
        this.loading = true
        this.accountUnion(this.pagination, data => {
          this.pagination.total = parseInt(data.total)
          this.accounts = data.list || []
          this.loading = false
        })
      },
      clearAll() {
        this.$refs.multipleTable.clearSelection()
        this.selects = []
        this.$emit('select-change', [])
      },
      clearSingle(data) {
        let idx = this.selects.findIndex(item => {
          return item.uuid === data.uuid
        })
        if (idx >= 0) {
          this.selects.splice(idx, 1)
          this.$emit('select-change', this.selects)
          this.$refs.multipleTable.toggleRowSelection(this.selects[idx], false);
        }
      },
      handleSelectionChange(val) {
        val = val || []
        // 过滤出当前页未选的元素
        this.selects = this.selects.filter(item => {
          let idx = -1
          for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].uuid === item.uuid) {
              idx = i
              break
            }
          }
          return idx === -1
        })

        val.forEach(res => {
          this.selects.push(res)
        })
        this.$emit('select-change', this.selects)
      },
      handleOrgCreate() {
        let check = checkObj(this.org, [
          {value: "name", message: "请输入组织名称"},
          {value: "orgType", message: "请选取组织类型"},
          {value: "phone", message: "请输入管理员手机号"},
          {value: "password", message: "请输入管理员密码"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.org.createOrgUuid = this.org.uuid
        this.org.createProveUuid = this.prove.uuid

        this.$api('account.org.create',{}, this.org).then(() => {
          this.org = {
            name: "",
            simpleName: "",
            orgType: 1,
            phone: "",
            password: "",
            createProveUuid: "",
            createOrgUuid: ""
          }
          this.$message.success('创建成功')
          this.loadAccount()
        })


      },
    },
  }
</script>

<style lang="scss" scoped>
  .account {
    &-list {
      &-title {
        margin-top: $middle-space;
        display: flex;
        margin-bottom: $middle-space;

        .name {
          @include page-title();
          flex: 1;
        }

        .cond {
          width: 240px;
        }
      }

      .department-name {
        display: block;
        min-width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
</style>
