<template>
  <account class="account">
    <el-table-column label="更多操作" fixed="right" width="180px">
      <template slot-scope="scope">
        <el-button v-if="$btnCheck('order_query_info')"
                   type="text" size="small" @click="handleAccountDetail(scope.row, 'order')"> 组织详情
        </el-button>
        <el-button v-if="$btnCheck('equipment_device_info')"
                   type="text" size="small" @click="handleApply(scope.row)">审核
        </el-button>
        <el-button v-if="$btnCheck('equipment_device_info')"
                   type="text" size="small" class="danger" @click="handleBan(scope.row)">禁用
        </el-button>
      </template>

    </el-table-column>
  </account>
</template>

<script>
  import Account from '../components/account'

  export default {
    name: '',
    components: {
      Account
    },
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {
      handleAccountDetail() {
      },
      handleApply() {
      },
      handleBan() {
      },
    },
  }
</script>

<style lang="scss" scoped>
  .account {
    @include container();
  }
</style>
